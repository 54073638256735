// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-20211007-tech-innovators-lead-charge-ensure-bitcoin-adoption-el-salvador-success-es-js": () => import("./../../../src/pages/20211007-Tech-Innovators-Lead-Charge-Ensure-Bitcoin-Adoption-El-Salvador-Success.es.js" /* webpackChunkName: "component---src-pages-20211007-tech-innovators-lead-charge-ensure-bitcoin-adoption-el-salvador-success-es-js" */),
  "component---src-pages-20211019-banco-hipotecario-invites-innovators-build-future-banking-el-salvador-en-js": () => import("./../../../src/pages/20211019-Banco-Hipotecario-Invites-Innovators-Build-Future-Banking-El-Salvador.en.js" /* webpackChunkName: "component---src-pages-20211019-banco-hipotecario-invites-innovators-build-future-banking-el-salvador-en-js" */),
  "component---src-pages-20211019-banco-hipotecario-invites-innovators-build-future-banking-el-salvador-es-js": () => import("./../../../src/pages/20211019-Banco-Hipotecario-Invites-Innovators-Build-Future-Banking-El-Salvador.es.js" /* webpackChunkName: "component---src-pages-20211019-banco-hipotecario-invites-innovators-build-future-banking-el-salvador-es-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pt-js": () => import("./../../../src/pages/index.pt.js" /* webpackChunkName: "component---src-pages-index-pt-js" */),
  "component---src-pages-playbook-en-js": () => import("./../../../src/pages/playbook.en.js" /* webpackChunkName: "component---src-pages-playbook-en-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../../../src/pages/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-terms-and-conditions-en-js": () => import("./../../../src/pages/terms-and-conditions.en.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-en-js" */)
}

