const addScript = url => {
    const script = document.createElement("script")
    script.src = url
    script.async = false
    document.body.appendChild(script)
  }
  
  export const onClientEntry = () => {
    window.onload = () => {
        addScript("https://code.createjs.com/1.0.0/createjs.min.js")
        addScript("/hero-animation/main.js"),
        addScript("/stack-adapt.js")
    }
  }